<template>
  <div class="w-full">
    <label v-if="label" class="mb-2 block font-bold text-gray-700">
      {{ label }}
    </label>
    <input
      v-model="value"
      type="text"
      :class="`border-with-shadow w-full rounded-xl px-4 py-3 outline-none ${inputClass}`"
      v-bind="$attrs"
      :placeholder="placeholder"
    />
  </div>
</template>

<script lang="ts" setup>
import type { InputHTMLAttributes } from "vue";

interface InputProps extends /* @vue-ignore */ InputHTMLAttributes {
  label?: string;
  inputClass?: string;
  placeholder?: string;
}

const value = defineModel();

defineProps<InputProps>();
defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();
</script>
